import React, { useState } from 'react';
import { useViewlogies } from 'context';
import './banner.scss';

const reduceOpacity = color => {
    const hex = color.replace(/^#/, '');

    // Parse r, g, b values
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${0.9})`;
};

const isValidWebsite = url => {
    const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,63})+)(\/[^\s]*)?$/;
    return regex.test(url);
};

const getCorrectedUrl = url => {
    if (url && typeof url === 'string' && isValidWebsite(url)) {
        if (!url.includes('http://') && !url.includes('https://')) {
            return `https://${url}`;
        } else {
            return url;
        }
    }
    return '';
};

const DonationBanner = () => {
    const {
        event: { donationUrl, donationUrl2, color, firstName }
    } = useViewlogies();

    const backgroundColor = color ?? '#173c4d';
    const [hover, setHover] = useState(false);
    const [hover2, setHover2] = useState(false);

    const message1 = firstName
        ? `In lieu of flowers, the family is asking to consider donating in ${firstName}’s name: CLICK HERE`
        : `In lieu of flowers, the family is asking to consider donating: CLICK HERE`;
    const message2 = 'Donation option #2: CLICK HERE';

    let urls = [];
    const correctedUrl = getCorrectedUrl(donationUrl);
    if (correctedUrl) {
        urls.push(correctedUrl);
    }

    const correctedUrl2 = getCorrectedUrl(donationUrl2);
    if (correctedUrl2) {
        urls.push(correctedUrl2);
    }

    return (
        <>
            {urls[0] && (
                <a
                    className="donation-banner top-banner"
                    style={{ backgroundColor: hover ? reduceOpacity(backgroundColor) : backgroundColor }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    href={urls[0]}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {message1}
                </a>
            )}
            {urls[1] && (
                <a
                    className="donation-banner"
                    style={{ backgroundColor: hover2 ? reduceOpacity(backgroundColor) : backgroundColor }}
                    onMouseEnter={() => setHover2(true)}
                    onMouseLeave={() => setHover2(false)}
                    href={urls[1]}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {message2}
                </a>
            )}
        </>
    );
};

export default DonationBanner;
