import React from 'react';
import { useViewlogies } from 'context';
import List from './List';
import './guestSection.scss';
import GuestForm from 'components/GuestForm';
import isEventStarted from 'components/GuestForm/isEventStarted';

const GuestSection = () => {
    const {
        event: { isGuestList },
        isPinLoggedIn,
        streams
    } = useViewlogies();

    // useEffect(() => { // TODO: use if we get backend auth working
    //     getEventLogin(organizationId, id)
    //         .then(({ isAuthorized }) => setIsPinLoggedIn(isAuthorized))
    //         .catch(e => {
    //             setIsPinLoggedIn(false);
    //             console.error(e);
    //         });
    // }, []);

    if (!isPinLoggedIn || !isGuestList) {
        return null;
    }

    const eventStarted = isEventStarted(streams);

    return (
        <>
            <hr />
            {eventStarted && (
                <div className="guest-section">
                    <GuestForm />
                    <List />
                </div>
            )}
        </>
    );
};

export default GuestSection;
