import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { useViewlogies } from 'context';
import { refresh, scheduleAtTime } from 'utils';
import { isAuthenticated } from 'services/viewlogyAuth';
import Header from 'components/ClientHeader';
import ContactUs from 'components/ContactUs';
import Footer from 'components/Footer';
import Subheader from './Subheader';
import Navigation from './Navigation';
import VideoPlayer from './VideoPlayer';
import GuestSection from './GuestSection';
import Resources from './Resources';
import sharedMessages from 'messages';
import './viewlogy.scss';
import DonationBanner from 'components/DonationBanner';

function useHashFragment(offset = 0, trigger = true) {
    useEffect(() => {
        const scrollToHashElement = () => {
            const { hash } = window.location;
            const elementToScroll = document.getElementById(hash?.replace('#', ''));

            if (!elementToScroll) return;

            window.scrollTo({
                top: elementToScroll.offsetTop - offset,
                behavior: 'smooth'
            });
        };

        if (!trigger) return;
        setTimeout(scrollToHashElement, 100);
        window.addEventListener('hashchange', scrollToHashElement);
        return () => window.removeEventListener('hashchange', scrollToHashElement);
    }, [trigger]);
}

const Viewlogy = props => {
    const {
        event,
        event: { firstName, lastName },
        videoLogin,
        streams,
        setIsLoggedIn,
        setIsPinLoggedIn,
        setStreams,
        setStreamIndex
    } = useViewlogies();
    const title = `${firstName} ${lastName} — ${sharedMessages.viewlogiesAllCaps}`;

    const eventStarted = new Date(streams[0].startTime).getTime() < new Date().getTime();

    const isSpecialEvent = streams.every(stream => stream.name === 'Special Event');

    useHashFragment();

    /**
     * 1 - GA tracking
     * 2 - Video authentication
     * 3 - Set currently visible video
     */
    useEffect(() => {
        // set title manually before tracking page view
        document.title = `${firstName} ${lastName} — ${sharedMessages.viewlogiesAllCaps}`;
        ReactGA.pageview(window.location.pathname);

        if (props.pin) {
            videoLogin(props.pin);
        }

        // check if authenticated
        if (event.globalPin) {
            const { globalPin: pin, id } = event;
            event.isLoggedIn = isAuthenticated({ pin, id, firstName });
            setIsLoggedIn(event.isLoggedIn);
            if (event.isLoggedIn) setIsPinLoggedIn(true);
        } else {
            setStreams(
                streams.map(stream => {
                    const { pin, id } = stream;
                    stream.isLoggedIn = isAuthenticated({ pin, id, firstName });
                    if (stream.isLoggedIn) setIsPinLoggedIn(true);
                    return stream;
                })
            );
        }

        // set current stream index
        const now = new Date();
        for (const [index, stream] of streams.entries()) {
            // if stream is currently running
            if (new Date(stream.startTime) < now && now < new Date(stream.endTime) && stream.id !== 'TRIBUTE_VIDEO') {
                setStreamIndex(index);
                return;
            }
        }
    }, []);

    /**
     * schedule automatic refreshes
     */
    useEffect(() => {
        // if global PIN and logged in, schedule refresh for all event start times
        if (event.globalPin && event.isLoggedIn) {
            streams.forEach((stream, index) => {
                // switch index to index of stream
                scheduleAtTime(stream.startTime, [refresh, () => setStreamIndex(stream, index)]);
            });
        } else {
            // else schedule a refresh for each stream that is logged in
            streams.forEach((stream, index) => {
                if (stream.isLoggedIn) {
                    scheduleAtTime(stream.startTime, [refresh, () => setStreamIndex(stream, index)]);
                }
            });
        }
    }, [event.isLoggedIn, streams]);

    return (
        <>
            <main className="main-content centered viewlogy">
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <DonationBanner />
                <Header />
                <Subheader />
                <Navigation />
                <VideoPlayer />
                <div id="fromqr"></div>
                <GuestSection />
                {/* Don't show funeral resources if event type is special */}
                {!isSpecialEvent && <Resources />}
                <ContactUs />
                <Footer />
            </main>
        </>
    );
};

export default Viewlogy;
