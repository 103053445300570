import React from 'react';
import { useViewlogies } from 'context';
import { FormattedMessage } from 'react-intl';
import Card from './Card';
import './resources.scss';
import obituaryImg from 'assets/obituary.jpg';
import planningAheadImg from 'assets/preplan.jpg';
import copingResourcesImg from 'assets/grief.jpg';

const Resources = () => {
    const {
        event: { obituaryUrl, preplanningUrl, griefSupportUrl, firstName, lastName, color, isLoggedIn }
    } = useViewlogies();

    if (!isLoggedIn) {
        return null;
    }

    let obituary = null;
    if (obituaryUrl) {
        const externalObituaryUrl =
            obituaryUrl.indexOf('http://') === 0 || obituaryUrl.indexOf('https://') === 0
                ? obituaryUrl
                : `//${obituaryUrl}`;

        obituary = (
            <Card
                imageSrc={obituaryImg}
                color={color}
                title={
                    <FormattedMessage
                        id="features.Viewlogy.Obituary.title"
                        defaultMessage="Memorialize Your Loved One"
                    />
                }
                description={
                    <FormattedMessage
                        id="features.Viewlogy.Obituary.description"
                        defaultMessage="Read the obituary, add your own photos, and spend additional time to honor the life of {1}."
                        values={{ 1: `${firstName} ${lastName}` }}
                    />
                }
                link={externalObituaryUrl}
                linkText={
                    <FormattedMessage
                        id="features.Viewlogy.Obituary.link"
                        defaultMessage="Visit Remembrance Page"
                        values={{ 1: `${firstName} ${lastName}` }}
                    />
                }
            />
        );
    }

    const planningAhead = (
        <Card
            imageSrc={planningAheadImg}
            color={color}
            title={
                <FormattedMessage
                    id="features.Viewlogy.Resources.planningAhead"
                    defaultMessage="Give The Ultimate Gift"
                />
            }
            description={
                <FormattedMessage
                    id="features.Viewlogy.Resources.planningAheadDescription"
                    defaultMessage="By planning for the inevitable, you insure peace of mind for your loved ones, avoid emotional spending, and protect against inflation."
                />
            }
            link={preplanningUrl}
            linkText={
                <FormattedMessage
                    id="features.Viewlogy.Resources.planningAheadLink"
                    defaultMessage="Learn about the benefits"
                />
            }
        />
    );

    const copingResources = (
        <Card
            imageSrc={copingResourcesImg}
            color={color}
            title={
                <FormattedMessage
                    id="features.Viewlogy.Resources.copingResources"
                    defaultMessage="Find Hope And Heal"
                />
            }
            description={
                <FormattedMessage
                    id="features.Viewlogy.Resources.copingDescription"
                    defaultMessage="We are sorry for your loss of {1}. We hope you will find moments of peace with these free videos."
                    values={{ 1: `${firstName} ${lastName}` }}
                />
            }
            //if griefSupportUrl is not set, use brightermornings.com
            link={griefSupportUrl ? griefSupportUrl : 'https://brightermornings.com/find-hope-and-heal'}
            linkText={
                <FormattedMessage id="features.Viewlogy.Resources.copingLink" defaultMessage="Get grief support" />
            }
        />
    );

    return (
        <>
            <hr />
            <div className="resources">
                {obituary}
                {copingResources}
                {planningAhead}
            </div>
        </>
    );
};

export default Resources;
